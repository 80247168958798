<!--
 * @Description: 在线课程
 * @Author: wangqiannan
 * @Date: 2022-08-05 09:00:00
-->

<template>
  <div class="ind-crouse w_auto">
    <div>
      <div class="ind-title flex-between">
        <div class="ind-name">
          <img src="@/assets/images/onlinecourses/hot.png" alt="" />
          <span class="font18 midt">{{item.floorName}}</span>
          <div class="ind-fl">
            <div
              v-for="(item,index) in gradeList"
              :key="index"
              :label="item.curriculumResourceId"
             
            >
              <span
                class="index_subnav"  @click="handleClick(item,index)"
                :class="activeIndex == index ? 'active' : ''"
                >{{ item.curriculumTheme }}
                <div class="aa"></div>
                </span>              
            </div>
          </div>
        </div>
        <div class="more">
          <span @click="courseClick">全部</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- <div v-for="(i,index) in gradeList" :key="index"> -->
        <Seven ref="course_ref" :items="typeIds" :kcvalue="item"></Seven>
      <!-- </div>      -->
    </div>
  </div>
</template>

<script>
import Seven from "./grade/gradeSeven";
export default {
  components: { Seven },
  props:{
    item: { 
      type:Object,
      default: () => {
        return {}
      }
    },
  },
  
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      activeName: "second",
      gradeList: [],
      // number: "0001",
      activeIndex: 10000000,
      page:1,
      limits:'',
      typeIds:{}
    };
  },
  mounted(){
    let typeId = item?.typeId??''
    if(typeId == ''){
        this.getCourseTypeListApi()
    }
  
  },
  methods: {
    handleClick(item,index) {
      this.activeIndex = index;
      
      this.typeIds = item
      this.$nextTick(function () {
        this.$refs.course_ref.getCourseListApi()
      });
      
    },
    courseClick(){
      this.$router.push({
        path:'/course/index'
      })
    },
    // 年级接口
    getCourseTypeListApi(){
      this.$api.getClientCurriculumResourceListApi({
        page:this.page,
        limit:this.item.showNumber,
        token:this.token,
        channelId: this.$http.channelId,
        parentId: 0
      }).then(res => {
        if(res.code == 10000){
          this.gradeList = res.data
          // this.typeIds = res.data[0]
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.active {
    color:#64a964;
  }
.ind-crouse[data-v-ce40753e] {
  width: 100%;
  display: inline-block;
}
.mt15 {
  margin-top: 15px;
}
.ind-title {
  padding: 15px 0;
  margin-bottom: 15px;
  align-items: center;
  background-color: #FFFFFF;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.ind-name {
  display: flex;
  align-items: center;
}
.ind-name img {
  margin-right: 15px;
}
.ind-name .midt {
  position: relative;
  margin-left: 5px;
  font-size: 18px;
}
.ind-fl {
  display: flex;
  margin-left: 20px;
}
.ind-fl span.index_subnav {
  padding: 3px 0px 5px;
  margin: 0 15px;
  position: relative;
  transition: 0.3s;
  font-size: 14px;
}
.ind-fl span {
  cursor: pointer;
}
.ind-fl span.index_subnav:hover {
  color: #3ce4bc;
}
.green {
  border-bottom: 1px solid #3ce4bc;
  color: #3ce4bc;
}

.aa {
  position: absolute;
  content: "";
  width: 1px;
  height: 10px;
  right: -15px;
  background: #d3d2d2;
  top: 50%;
  margin-top: -5px;
}
.aa:last-child{
  position: absolute;
  content: "";
  width: 0px;
  height: 10px;
  right: -15px;
  background: #d3d2d2;
  top: 50%;
  margin-top: -5px;
}
// .index_subnav:last-child{
//   width: 0px;
//   height: 10px;
// }
.more {
  font-size: 14px;
  cursor: pointer;
  padding-right: 10px;
}
</style>